export const fimalacRoutes = [
	{
		path: 'time-cards',
		// meta: { label: 'Fimalac' },
		component: {
			render(c) {
				return c('router-view');
			}
		},
		children: [
			{
				path: 'planning',
				meta: { label: 'Time card planning' },
				component: () => import('@/modules/fimalac/views/index.vue'),
				name: 'fimalacTimecardPlanning'
			}
		]
	},
	{
		path: 'maintenance',
		meta: { label: 'Maintenance' },
		component: () => import('@/modules/fimalac/components/Maintenance/Maintenance.vue'),
		name: 'Maintenance'
	}
];
